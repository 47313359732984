<template>
  <a-row style="height: 100%" :gutter="[10, 10]" wrap>
    <a-col :lg="8" :xl="5">
      <div class="l-left-search-container" style="background: #fff">
        <l-form :rules="rules" :model="formState" ref="formRef">
          <a-row :gutter="20">
            <a-col :span="24">
              <a-form-item label="俱乐部ID" name="clubId">
                <a-input v-model:value="formState.clubId" placeholder="请输入俱乐部ID" autocomplete="off" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="成员游戏ID" name="memberMaskId">
                <a-input v-model:value="formState.memberMaskId" placeholder="请输入成员游戏ID" autocomplete="off" />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item label="查询日期范围" name="dateRange">
                <a-row type="flex" :gutter="10" :wrap="false">
                  <a-col :flex="1">
                    <a-date-picker v-model:value="formState.startDate" inputReadOnly :allowClear="false" placeholder="开始日期" value-format="YYYY-MM-DD" style="display: block" />
                  </a-col>

                  <a-col flex="20px"> <div style="margin-top: 5px">~</div> </a-col>

                  <a-col :flex="1">
                    <a-date-picker v-model:value="formState.endDate" :allowClear="false" inputReadOnly placeholder="结束日期" value-format="YYYY-MM-DD" style="display: block" />
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>

          <div>
            <a-button style="margin-right: 8px" @click="handleReset">重置</a-button>
            <a-button type="primary" @click="handleSearch">搜索</a-button>
          </div>
        </l-form>
      </div>
    </a-col>
    <a-col :lg="16" :xl="19">
      <a-card title="" :tab-list="tabList" :active-tab-key="activeKey" @tabChange="handleTabChange">
        <div v-show="activeKey == '1'">
          <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="index" :loading="loading">
            <template v-slot:toolbar-left>
              <a-input-search v-model:value="search.data.childMemberMaskId" placeholder="请输如成员游戏ID" enter-button @search="onSearch" style="width: 200px" />
            </template>

            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'player'">
                <div>{{ record.memberName }}</div>
                <div>游戏ID：{{ record.memberMaskId }}，userID：{{ record.memberId }}</div>
              </template>
              <template v-if="column.dataIndex === 'action'">
                <l-auth-text-button auth="gm_club_income_statistics_btn" type="primary" primary @click="showDetails(record)">
                  <InfoCircleOutlined />
                  详情
                </l-auth-text-button>
              </template>
            </template>

            <template #summary>
              <a-table-summary fixed>
                <a-table-summary-row>
                  <a-table-summary-cell><b>合计</b></a-table-summary-cell>
                  <a-table-summary-cell>
                    <a-typography-text
                      ><b>{{ totalData.personValid }}</b></a-typography-text
                    >
                  </a-table-summary-cell>
                  <a-table-summary-cell>
                    <a-typography-text
                      ><b>{{ totalData.groupValid }}</b></a-typography-text
                    >
                  </a-table-summary-cell>
                  <a-table-summary-cell>
                    <a-typography-text
                      ><b>{{ totalData.gameCount }}</b></a-typography-text
                    >
                  </a-table-summary-cell>
                  <a-table-summary-cell>
                    <a-typography-text
                      ><b>{{ totalData.consumeCard }}</b></a-typography-text
                    >
                  </a-table-summary-cell>
                  <a-table-summary-cell>
                    <a-typography-text
                      ><b>{{ totalData.winnerNumber }}</b></a-typography-text
                    >
                  </a-table-summary-cell>
                  <a-table-summary-cell>
                    <a-typography-text
                      ><b>{{ totalData.totalNumber }}</b></a-typography-text
                    >
                  </a-table-summary-cell>
                </a-table-summary-row>
              </a-table-summary>

              <!-- <a-table-summary-row>
                <a-table-summary-cell>Balance</a-table-summary-cell>
                <a-table-summary-cell :col-span="2">
                  <a-typography-text type="danger">
                    {{ totals.totalBorrow - totals.totalRepayment }}
                  </a-typography-text>
                </a-table-summary-cell>
              </a-table-summary-row> -->
            </template>
          </l-table>
        </div>

        <div v-show="activeKey == '2'">
          <l-table @reload="getTeamList" :columns="teamColumns" @expand="getTeamChildren" :indentSize="50" :data-source="teamData.data" row-key="memberId" :expanded-row-keys="teamData.expandKeys" :loading="teamData.loading" :pagination="false">
            <template v-slot:toolbar-left>
              <a-input-search v-model:value="teamData.search.data.childMemberMaskId" placeholder="请输如成员游戏ID" enter-button @search="onTeamSearch" style="width: 200px" />
            </template>

            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'player'">
                <div>{{ record.memberName }}</div>
                <div>游戏ID：{{ record.memberMaskId }}，userID：{{ record.memberId }}</div>
                <div>等级：{{ record.memberLevel }}，身份：{{ getLabelByValue(clubMemberIdentitys, record.memberIdentity) }}</div>
              </template>
              <template v-if="column.dataIndex === 'action'">
                <l-auth-text-button auth="gm_club_income_statistics_btn" type="primary" primary @click="showDetails(record)">
                  <InfoCircleOutlined />
                  详情
                </l-auth-text-button>
              </template>
            </template>
          </l-table>
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<script lang="ts">
import http from "@/common/http";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { PaginationProps } from "ant-design-vue";
import { getPageOffset } from "@/common/utils";
import { clubMemberIdentitys, getLabelByValue } from "@/common/head";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    // Edit,
  },
  setup() {
    const columns = ref([
      //   {
      //     title: "序号",
      //     width: 70,
      //     dataIndex: "index",
      //     fixed: "left",
      //   },

      {
        title: "玩家",
        width: 150,
        dataIndex: "player",
        // ellipsis: true,
      },

      {
        title: "有效人次",
        width: 80,
        dataIndex: "personValid",
        resizable: true,
      },

      {
        title: "有效场次",
        width: 80,
        dataIndex: "groupValid",
        resizable: true,
      },

      {
        title: "总牌局数",
        width: 80,
        dataIndex: "gameCount",
        resizable: true,
      },

      {
        title: "耗钻",
        width: 80,
        dataIndex: "consumeCard",
        resizable: true,
      },

      {
        title: "大赢家",
        width: 80,
        dataIndex: "winnerNumber",
        resizable: true,
      },

      {
        title: "战绩",
        width: 80,
        dataIndex: "totalNumber",
        resizable: true,
        // fixed: "right",
      },

      // {
      //   title: "操作",
      //   align: "center",
      //   key: "action",
      //   dataIndex: "action",
      //   fixed: "right",
      //   width: 60,
      // },
    ]);

    const teamColumns = ref([
      // {
      //   title: "序号",
      //   width: 70,
      //   dataIndex: "index",
      //   fixed: "left",
      // },

      // {
      //   title: "玩家游戏ID",
      //   width: 80,
      //   dataIndex: "memberMaskId",
      // },

      {
        title: "玩家",
        width: 150,
        dataIndex: "player",
        // ellipsis: true,
      },

      {
        title: "有效人次",
        width: 80,
        dataIndex: "personValid",
        // ellipsis: true,
        resizable: true,
      },

      {
        title: "有效场次",
        width: 80,
        dataIndex: "groupValid",
        // ellipsis: true,
        resizable: true,
      },

      {
        title: "总牌局数",
        width: 80,
        dataIndex: "gameCount",
        // ellipsis: true,
        resizable: true,
      },

      {
        title: "耗钻",
        width: 80,
        dataIndex: "consumeCard",
        // ellipsis: true,
        resizable: true,
      },

      {
        title: "大赢家",
        width: 80,
        dataIndex: "winnerNumber",
        ellipsis: true,
        resizable: true,
      },

      {
        title: "战绩",
        width: 80,
        dataIndex: "totalNumber",
        ellipsis: true,
        resizable: true,
        // fixed: "right",
      },

      // {
      //   title: "操作",
      //   align: "center",
      //   key: "action",
      //   dataIndex: "action",
      //   fixed: "right",
      //   width: 60,
      // },
    ]);

    const tabList = ref([
      {
        key: "1",
        tab: "个人",
      },
      {
        key: "2",
        tab: "团队",
      },
    ]);

    const isAg = ref(false);

    //表格加载中
    const loading = ref(false);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    //合计信息
    const totalData = ref({
      personValid: 0,
      groupValid: 0,
      gameCount: 0,
      consumeCard: 0,
      winnerNumber: 0,
      totalNumber: 0,
    });

    const formRef = ref();

    //团队信息
    const teamData: any = reactive({
      loading: false,
      expandKeys: [],
      data: [],
      search: {
        data: {
          childMemberMaskId: "",
        },
        relData: {},
      },
    });

    //切换团队/个人选项卡
    const activeKey = ref("1");
    const handleTabChange = (key: any) => {
      activeKey.value = key;
    };

    //查看详情
    const detailsRow = ref<any>({});
    const detailsVisible = ref(false);

    //搜索的初始化数据
    const rules = {
      memberMaskId: [{ required: true, message: "请输入成员游戏ID", trigger: "change" }],
      clubId: [{ required: true, message: "请输入俱乐部ID", trigger: "change" }],
      dateRange: [{ required: true, message: "请输入日期范围", trigger: "change" }],
    };
    const formState = reactive({
      clubId: "",
      memberMaskId: "",
      dateRange: "1",
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    });

    const handleSearch = async () => {
      formRef.value.validate().then(async () => {
        pagination.current = 1;
        data.value = [];
        pagination.total = 0;

        getPersonalStatistics();
        await getPersonalList();
        //如果是代理或会长，查团队信息
        if (!isAg.value) {
          tabList.value = [
            {
              key: "1",
              tab: "个人",
            },
          ];
          activeKey.value = "1";
        } else {
          tabList.value = [
            {
              key: "1",
              tab: "个人",
            },
            {
              key: "2",
              tab: "团队",
            },
          ];
          getTeamList();
        }
      });
    };
    const handleReset = () => {
      formState.startDate = dayjs().format("YYYY-MM-DD");
      formState.endDate = dayjs().format("YYYY-MM-DD");
      formRef.value.resetFields();

      search.relData = {};
      search.data = {
        childMemberMaskId: "",
      };

      teamData.search.relData = {};
      teamData.search.data = {
        childMemberMaskId: "",
      };

      pagination.current = 1;
      data.value = [];
      pagination.total = 0;

      teamData.loading = false;
      teamData.expandKeys = [];
      teamData.data = [];
      //   handleSearch();
    };

    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        childMemberMaskId: "",
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //获取个人列表
    const getPersonalList = async () => {
      try {
        console.log(formState);

        loading.value = true;
        const result = await http.get("us_club_standings/personal", {
          page: pagination.current,
          page_size: pagination.pageSize,
          ...formState,
          ...search.relData,
        });

        isAg.value = result.data.isAg;

        let offset = getPageOffset(pagination.current!, pagination.pageSize!);
        data.value = result.data.data.map((item: any) => {
          item.index = ++offset;
          return item;
        });
        pagination.total = result.data.count;

        loading.value = false;
      } catch (e) {
        loading.value = false;
      }
    };

    //获取个人统计信息
    const getPersonalStatistics = async () => {
      try {
        loading.value = true;
        const result = await http.get("us_club_standings/personal_statistics", {
          ...formState,
          ...search.relData,
        });

        totalData.value = result.data.data;

        loading.value = false;
      } catch (e) {
        loading.value = false;
      }
    };

    //获取团队列表
    const getTeamList = async () => {
      teamData.loading = true;
      teamData.expandKeys = [];
      teamData.data = [];
      const result = await http.get("us_club_standings/team", {
        ...formState,
        ...teamData.search.relData,
      });

      teamData.data = result.data.data.map((item: any) => {
        if (item.childrenCount > 0) {
          item.children = [];
        }
        return item;
      });
      // pagination.total = result.data.count;

      teamData.loading = false;
    };

    //根据游戏ID获取所有的直属下级
    const getTeamChildren = async (expand: boolean, item: any) => {
      if (!expand) return;
      teamData.expandKeys.push(item.memberId);
      const result = await http.get("us_club_standings/team", {
        ...formState,
        memberMaskId: item.memberMaskId,
      });

      item.children = result.data.data.map((item: any) => {
        if (item.childrenCount > 0) {
          item.children = [];
        }
        return item;
      });
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getPersonalList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;

      data.value = [];
      pagination.total = 0;
      getPersonalList();
      getPersonalStatistics();
    };

    //点击团队搜索按钮
    const onTeamSearch = () => {
      teamData.search.relData = teamData.search.data;
      getTeamList();
    };

    //显示详情
    const showDetails = (row: any) => {
      detailsRow.value = row;
      nextTick(() => {
        detailsVisible.value = true;
      });
    };

    onMounted(() => {
      // getList();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      handleTableChange,
      onSearch,
      searchCol,
      getLabelByValue,
      clubMemberIdentitys,
      showDetails,
      activeKey,
      rules,
      formState,
      handleSearch,
      handleReset,
      handleTabChange,
      tabList,
      teamData,
      teamColumns,
      totalData,
      getTeamChildren,
      getTeamList,
      onTeamSearch,
    };
  },
});
</script>

<style lang="scss" scoped>
.l-left-search-container {
  background: #fff;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  padding: 20px 20px;
  height: 100%;
  max-width: 100%;
}
</style>
